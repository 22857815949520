(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name referees.referee.controller:RefereeCtrl
   *
   * @description
   *
   */
  angular
    .module('neo.home.votacio')
    .controller('votacioCtrl', votacioCtrl);

  function votacioCtrl(resultat,perfil, $state,$scope,equip,valoraciones,valoraciones2,items,partit, valoracions)
  {
     var vm=this;
    // vm.items=items.slice(0,items.length-1);
    vm.items=items;
    vm.show=false;
     vm.observacions=items[items.length-1].valor;
     if(resultat.length===1){
       var r={
       fecha:resultat[0].fecha,
       campionat:resultat[0].campionat,
        elocal:resultat[0].elocal,
    evisitant:resultat[0].evisitant,
    cc:[0].cc,
       pp:0,
       ptg:0,
       ptt:0,
       pte:0,
       pdt:0,
       pdp:0,
       marcador:0,
       verdplay:0,
       tv:0,
       puntsVisitant:0,totpunts:0,
       idEquipo:resultat[0].idVisitant,
       idPartido:resultat[0].idPartido,
       idLocal:resultat[0].idLocal,
       idVisitant:resultat[0].idVisitant
          };
       
       resultat.push(r);
   }
     if (vm.observacions===0){
         vm.observacions="";
     }
     vm.itempos=[];
     vm.valoracions=valoraciones;
     vm.valoracions2=valoraciones2;
     vm.perfil=perfil;
     if(vm.perfil==="5"){
     
   var v2=vm.valoracions.concat(vm.valoracions2);
 // alert(vm.valoracions2.length.toString()+"-"+vm.valoracions.length.toString()+"-"+v2.length.toString());
  vm.valoracions=v2;
   }
      $scope.opcions=[];
      $scope.valors=[];
 //     $scope.opcions[0]= [{ id: 1, name: "Gairebé mai"},{ id: 2, name: "Gairebé mai"},{ id: 3, name: "Gairebé mai"},{ id: 4, name: "Gairebé mai"}];
 //     $scope.opcions[1]= [{ id: 1, name: "2Gairebé mai"},{ id: 2, name: "Gairebé mai"},{ id: 3, name: "Gairebé mai"},{ id: 4, name: "Gairebé mai"}];
//      $scope.opcions[2]= [{ id: 1, name: "3Gairebé mai"},{ id: 2, name: "Gairebé mai"},{ id: 3, name: "Gairebé mai"},{ id: 4, name: "Gairebé mai"}];
//      $scope.opcions[3]= [{ id: 1, name: "4Gairebé mai"},{ id: 2, name: "Gairebé mai"},{ id: 3, name: "Gairebé mai"},{ id: 4, name: "Gairebé mai"}];
//  $scope.opcions[4]= [{ id: 1, name: "5Gairebé mai"},{ id: 2, name: "Gairebé mai"},{ id: 3, name: "Gairebé mai"},{ id: 4, name: "Gairebé mai"}];
////  $scope.opcions[5]= [{ id: 1, name: "6Gairebé mai"},{ id: 2, name: "Gairebé mai"},{ id: 3, name: "Gairebé mai"},{ id: 4, name: "Gairebé mai"}];
//  $scope.opcions[6]= [{ id: 1, name: "7Gairebé mai"},{ id: 2, name: "Gairebé mai"},{ id: 3, name: "Gairebé mai"},{ id: 4, name: "Gairebé mai"}];
 $scope.item35={iditem:0,nom:"",respetoa:""};
 $scope.opcions35=[];
 var valorspos35=[];
 $scope.item36={iditem:0,nom:"",respetoa:""};
 $scope.opcions36=[];
 var valorspos36=[];
 $scope.item8={iditem:0,nom:"",respetoa:""};
 $scope.opcions8=[];
 var valorspos8=[];
 var valorscsv8=[];
 $scope.item9={iditem:0,nom:"",respetoa:""};
 $scope.opcions9=[];
 var valorspos9=[];
 var valorscsv9=[];
 $scope.item17={iditem:0,nom:"",respetoa:""};
 $scope.opcions17=[];
 var valorspos17=[];
 var valorscsv17=[];
 $scope.item18={iditem:0,nom:"",respetoa:""};
 $scope.opcions18=[];
 var valorspos18=[];
 var valorscsv18=[];
 $scope.item19={iditem:0,nom:"",respetoa:""};
 $scope.opcions19=[];
 var valorspos19=[];
 var valorscsv19=[];
 $scope.item20={iditem:0,nom:"",respetoa:""};
 $scope.opcions20=[];
 var valorspos20=[];
 var valorscsv20=[];
 $scope.item21={iditem:0,nom:"",respetoa:""};
 $scope.opcions21=[];
 var valorspos21=[];
 var valorscsv21=[];
 $scope.item22={iditem:0,nom:"",respetoa:""};
 $scope.opcions22=[];
 var valorspos22=[];
 var valorscsv22=[];
 $scope.item23={iditem:0,nom:"",respetoa:""};
 $scope.opcions23=[];
 var valorspos23=[];
 var valorscsv23=[];
 $scope.item24={iditem:0,nom:"",respetoa:""};
 $scope.opcions24=[];
 var valorspos24=[];
 var valorscsv24=[];
 $scope.item25={iditem:0,nom:"",respetoa:""};
 $scope.opcions25=[];
 var valorspos25=[];
 var valorscsv25=[];
 $scope.item26={iditem:0,nom:"",respetoa:""};
 $scope.opcions26=[];
 var valorspos26=[];
 var valorscsv26=[];
 $scope.item27={iditem:0,nom:"",respetoa:""};
 $scope.opcions27=[];
 var valorspos27=[];
 var valorscsv27=[];
 $scope.item28={iditem:0,nom:"",respetoa:""};
 $scope.opcions28=[];
 var valorspos28=[];
 var valorscsv28=[];
 $scope.item29={iditem:0,nom:"",respetoa:""};
 $scope.opcions29=[];
 var valorspos29=[];
 var valorscsv29=[];
 $scope.item30={iditem:0,nom:"",respetoa:""};
 $scope.opcions30=[];
 var valorspos30=[];
 var valorscsv30=[];
 $scope.item31={iditem:0,nom:"",respetoa:""};
 $scope.opcions31=[];
 var valorspos31=[];
 var valorscsv31=[];
 $scope.item35={iditem:0,nom:"",respetoa:""};
 $scope.opcions35=[];
 var valorspos35=[];
 $scope.item36={iditem:0,nom:"",respetoa:""};
 $scope.opcions36=[];
 var valorspos36=[];
 $scope.item37={iditem:0,nom:"",respetoa:""};
 $scope.opcions37=[];
 var valorspos37=[];
 var valorscsv37=[];
 $scope.item38={iditem:0,nom:"",respetoa:""};
 $scope.opcions38=[];
 var valorspos38=[];
 var valorscsv38=[];
 $scope.item39={iditem:0,nom:"",respetoa:""};
 $scope.opcions39=[];
 var valorspos39=[];
 var valorscsv39=[];
 $scope.item40={iditem:0,nom:"",respetoa:""};
 $scope.opcions40=[];
 var valorspos40=[];
 var valorscsv40=[];
 $scope.item41={iditem:0,nom:"",respetoa:""};
 $scope.opcions41=[];
 var valorspos41=[];
 var valorscsv41=[];
 $scope.item42={iditem:0,nom:"",respetoa:""};
 $scope.opcions42=[];
 var valorspos42=[];
 $scope.item43={iditem:0,nom:"",respetoa:""};
 $scope.opcions43=[];
 $scope.opcions60=[];
 $scope.opcions61=[];
 var valorspos43=[];
    for(var j=0;j<vm.items.length;j++)
     {    
         if(vm.items[j].iditem===43){
             $scope.item43.iditem=43;
             $scope.item43.nom=vm.items[j].nom;
             $scope.item43.respetoa=vm.items[j].respestoa;
            var a=vm.items[j].valorsCSV.split(";"); 
            var valors=vm.items[j].valoresposbiles.split(";");
                 for(var i=0;i<100;i++){
             $scope.opcions43[i]={id:i,name:i.toString(),selec:0};
             valorspos43[i]=i.toString();
         }
         }
         if(vm.items[j].iditem===42){
             $scope.item42.iditem=42;
             $scope.item42.nom=vm.items[j].nom;
             $scope.item42.respetoa=vm.items[j].respestoa;
            var a=vm.items[j].valorsCSV.split(";"); 
            var valors=vm.items[j].valoresposbiles.split(";");
               for(var i=0;i<100;i++){
             $scope.opcions42[i]={id:i,name:i.toString(),selec:0};
             valorspos42[i]=i.toString();
         }
         }
         if(vm.items[j].iditem===41){
             $scope.item41.iditem=41;
             $scope.item41.nom=vm.items[j].nom;
             $scope.item41.respetoa=vm.items[j].respestoa;
            var a=vm.items[j].valorsCSV.split(";"); 
            var valors=vm.items[j].valoresposbiles.split(";");
               for(var i=0;i<a.length;i++){
             $scope.opcions41[i]={id:i,name:a[i],selec:0};
             valorspos41[i]=valors[i];
             valorscsv41[i]=a[i];
         }
       
         }
         if(vm.items[j].iditem===40){
             $scope.item40.iditem=40;
             $scope.item40.nom=vm.items[j].nom;
             $scope.item40.respetoa=vm.items[j].respestoa;
            var a=vm.items[j].valorsCSV.split(";"); 
            var valors=vm.items[j].valoresposbiles.split(";");
               for(var i=0;i<a.length;i++){
             $scope.opcions40[i]={id:i,name:a[i],selec:0};
             valorspos40[i]=valors[i];
             valorscsv40[i]=a[i];
         }
         }
         if(vm.items[j].iditem===39){
             $scope.item39.iditem=39;
             $scope.item39.nom=vm.items[j].nom;
             $scope.item39.respetoa=vm.items[j].respestoa;
            var a=vm.items[j].valorsCSV.split(";"); 
            var valors=vm.items[j].valoresposbiles.split(";");
               for(var i=0;i<a.length;i++){
             $scope.opcions39[i]={id:i,name:a[i],selec:0};
             valorspos39[i]=valors[i];
             valorscsv39[i]=a[i];
         }
         }
         if(vm.items[j].iditem===38){
             $scope.item38.iditem=38;
             $scope.item38.nom=vm.items[j].nom;
             $scope.item38.respetoa=vm.items[j].respestoa;
            var a=vm.items[j].valorsCSV.split(";"); 
            var valors=vm.items[j].valoresposbiles.split(";");
               for(var i=0;i<a.length;i++){
             $scope.opcions38[i]={id:i,name:a[i],selec:0};
             valorspos38[i]=valors[i];
             valorscsv38[i]=a[i];
         }
         }
         if(vm.items[j].iditem===37){
             $scope.item37.iditem=37;
             $scope.item37.nom=vm.items[j].nom;
             $scope.item37.respetoa=vm.items[j].respestoa;
            var a=vm.items[j].valorsCSV.split(";"); 
            var valors=vm.items[j].valoresposbiles.split(";");
               for(var i=0;i<a.length;i++){
             $scope.opcions37[i]={id:i,name:a[i],selec:0};
             valorspos37[i]=valors[i];
             valorscsv37[i]=a[i];
         }
         }
       
       
         if(vm.items[j].iditem===31){
             $scope.item31.iditem=31;
             $scope.item31.nom=vm.items[j].nom;
             $scope.item31.respetoa=vm.items[j].respestoa;
            var a=vm.items[j].valorsCSV.split(";"); 
       
            var valors=vm.items[j].valoresposbiles.split(";");
               for(var i=0;i<a.length;i++){
             $scope.opcions31[i]={id:i,name:a[i],selec:0};
             valorspos31[i]=valors[i];
             valorscsv31[i]=a[i];
         }
         }
         if(vm.items[j].iditem===30){
             $scope.item30.iditem=30;
             $scope.item30.nom=vm.items[j].nom;
             $scope.item30.respetoa=vm.items[j].respestoa;
            var a=vm.items[j].valorsCSV.split(";"); 
            var valors=vm.items[j].valoresposbiles.split(";");
               for(var i=0;i<a.length;i++){
             $scope.opcions30[i]={id:i,name:a[i],selec:0};
             valorspos30[i]=valors[i];
             valorscsv30[i]=a[i];
         }
         }
           if(vm.items[j].iditem===29){
             $scope.item29.iditem=29;
             $scope.item29.nom=vm.items[j].nom;
             $scope.item29.respetoa=vm.items[j].respestoa;
            var a=vm.items[j].valorsCSV.split(";"); 
            var valors=vm.items[j].valoresposbiles.split(";");
               for(var i=0;i<a.length;i++){
             $scope.opcions29[i]={id:i,name:a[i],selec:0};
             valorspos29[i]=valors[i];
             valorscsv29[i]=a[i];
         }
         }
           if(vm.items[j].iditem===28){
             $scope.item28.iditem=28;
             $scope.item28.nom=vm.items[j].nom;
             $scope.item28.respetoa=vm.items[j].respestoa;
            var a=vm.items[j].valorsCSV.split(";"); 
            var valors=vm.items[j].valoresposbiles.split(";");
               for(var i=0;i<a.length;i++){
             $scope.opcions28[i]={id:i,name:a[i],selec:0};
             valorspos28[i]=valors[i];
             valorscsv28[i]=a[i];
         }
         }
           if(vm.items[j].iditem===27){
             $scope.item27.iditem=27;
             $scope.item27.nom=vm.items[j].nom;
             $scope.item27.respetoa=vm.items[j].respestoa;
            var a=vm.items[j].valorsCSV.split(";"); 
            var valors=vm.items[j].valoresposbiles.split(";");
               for(var i=0;i<a.length;i++){
             $scope.opcions27[i]={id:i,name:a[i],selec:0};
             valorspos27[i]=valors[i];
             valorscsv27[i]=a[i];
         }
         }
          if(vm.items[j].iditem===26){
             $scope.item26.iditem=26;
             $scope.item26.nom=vm.items[j].nom;
             $scope.item26.respetoa=vm.items[j].respestoa;
            var a=vm.items[j].valorsCSV.split(";"); 
            var valors=vm.items[j].valoresposbiles.split(";");
               for(var i=0;i<a.length;i++){
             $scope.opcions26[i]={id:i,name:a[i],selec:0};
             valorspos26[i]=valors[i];
             valorscsv26[i]=a[i];
         }
         }
          if(vm.items[j].iditem===25){
             $scope.item25.iditem=25;
             $scope.item25.nom=vm.items[j].nom;
             $scope.item25.respetoa=vm.items[j].respestoa;
            var a=vm.items[j].valorsCSV.split(";"); 
            var valors=vm.items[j].valoresposbiles.split(";");
               for(var i=0;i<a.length;i++){
             $scope.opcions25[i]={id:i,name:a[i],selec:0};
             valorspos25[i]=valors[i];
             valorscsv25[i]=a[i];
         }
         }
          if(vm.items[j].iditem===24){
             $scope.item24.iditem=24;
             $scope.item24.nom=vm.items[j].nom;
             $scope.item24.respetoa=vm.items[j].respestoa;
            var a=vm.items[j].valorsCSV.split(";"); 
            var valors=vm.items[j].valoresposbiles.split(";");
               for(var i=0;i<a.length;i++){
             $scope.opcions24[i]={id:i,name:a[i],selec:0};
             valorspos24[i]=valors[i];
             valorscsv24[i]=a[i];
         }
         }
          if(vm.items[j].iditem===23){
             $scope.item23.iditem=23;
             $scope.item23.nom=vm.items[j].nom;
             $scope.item23.respetoa=vm.items[j].respestoa;
            var a=vm.items[j].valorsCSV.split(";"); 
            var valors=vm.items[j].valoresposbiles.split(";");
               for(var i=0;i<a.length;i++){
             $scope.opcions23[i]={id:i,name:a[i],selec:0};
             valorspos23[i]=valors[i];
             valorscsv23[i]=a[i];
         }
         }
          if(vm.items[j].iditem===22){
             $scope.item22.iditem=22;
             $scope.item22.nom=vm.items[j].nom;
             $scope.item22.respetoa=vm.items[j].respestoa;
            var a=vm.items[j].valorsCSV.split(";"); 
            var valors=vm.items[j].valoresposbiles.split(";");
               for(var i=0;i<a.length;i++){
             $scope.opcions22[i]={id:i,name:a[i],selec:0};
             valorspos22[i]=valors[i];
             valorscsv22[i]=a[i];
         }
         }
         if(vm.items[j].iditem===21){
             $scope.item21.iditem=21;
             $scope.item21.nom=vm.items[j].nom;
             $scope.item21.respetoa=vm.items[j].respestoa;
            var a=vm.items[j].valorsCSV.split(";"); 
            var valors=vm.items[j].valoresposbiles.split(";");
               for(var i=0;i<a.length;i++){
             $scope.opcions21[i]={id:i,name:a[i],selec:0};
             valorspos21[i]=valors[i];
             valorscsv21[i]=a[i];
         }
         }
           if(vm.items[j].iditem===20){
             $scope.item20.iditem=20;
             $scope.item20.nom=vm.items[j].nom;
             $scope.item20.respetoa=vm.items[j].respestoa;
            var a=vm.items[j].valorsCSV.split(";"); 
            var valors=vm.items[j].valoresposbiles.split(";");
               for(var i=0;i<a.length;i++){
                  
             $scope.opcions20[i]={id:i,name:a[i],selec:0};
             valorspos20[i]=valors[i];
             valorscsv20[i]=a[i];
         }
         }
           if(vm.items[j].iditem===19){
             $scope.item19.iditem=19;
             $scope.item19.nom=vm.items[j].nom;
             $scope.item19.respetoa=vm.items[j].respestoa;
            var a=vm.items[j].valorsCSV.split(";"); 
            var valors=vm.items[j].valoresposbiles.split(";");
               for(var i=0;i<a.length;i++){
             $scope.opcions19[i]={id:i,name:a[i],selec:0};
             valorspos19[i]=valors[i];
             valorscsv19[i]=a[i];
             
         }
         }
          if(vm.items[j].iditem===18){
             $scope.item18.iditem=18;
             $scope.item18.nom=vm.items[j].nom;
             $scope.item18.respetoa=vm.items[j].respestoa;
            var a=vm.items[j].valorsCSV.split(";"); 
            var valors=vm.items[j].valoresposbiles.split(";");
               for(var i=0;i<a.length;i++){
             $scope.opcions18[i]={id:i,name:a[i],selec:0};
             valorspos18[i]=valors[i];
             valorscsv18[i]=a[i];
         }
         }
         if(vm.items[j].iditem===17){
             $scope.item17.iditem=17;
             $scope.item17.nom=vm.items[j].nom;
             $scope.item17.respetoa=vm.items[j].respestoa;
            var a=vm.items[j].valorsCSV.split(";"); 
            var valors=vm.items[j].valoresposbiles.split(";");
               for(var i=0;i<a.length;i++){
             $scope.opcions17[i]={id:i,name:a[i],selec:0};
             valorspos17[i]=valors[i];
             valorscsv17[i]=a[i];
         }
         }
           if(vm.items[j].iditem===9){
             $scope.item9.iditem=9;
             $scope.item9.nom=vm.items[j].nom;
             $scope.item9.respetoa=vm.items[j].respestoa;
            var a=vm.items[j].valorsCSV.split(";"); 
            var valors=vm.items[j].valoresposbiles.split(";");
               for(var i=0;i<a.length;i++){
             $scope.opcions9[i]={id:i,name:a[i],selec:0};
             valorspos9[i]=valors[i];
               valorscsv9[i]=a[i];
         }
         }
         if(vm.items[j].iditem===8){
             $scope.item8.iditem=8;
             $scope.item8.nom=vm.items[j].nom;
             $scope.item8.respetoa=vm.items[j].respestoa;
            var a=vm.items[j].valorsCSV.split(";"); 
            var valors=vm.items[j].valoresposbiles.split(";");
               for(var i=0;i<a.length;i++){
             $scope.opcions8[i]={id:i,name:a[i],selec:0};
             valorspos8[i]=valors[i];
             valorscsv8[i]=a[i];
         }
         }
         if(vm.items[j].iditem===35){
             $scope.item35.iditem=35;
             $scope.item35.nom=vm.items[j].nom;
             $scope.item35.respetoa=vm.items[j].respestoa;
           
               for(var i=0;i<100;i++){
            $scope.opcions60[i]={id:i,name:i.toString(),selec:0};
        }
            
           $scope.opcions35[0]={id:0,name:"Guanya",selec:0};
           $scope.opcions35[1]={id:1,name:"Empata",selec:0};
           $scope.opcions35[2]={id:2,name:"Perd",selec:0};
             valorspos35[0]=100;
             valorspos35[1]=66;
             valorspos35[2]=33;
         
         }
         
         if(vm.items[j].iditem===36){
             $scope.item36.iditem=36;
             $scope.item36.nom=vm.items[j].nom;
             $scope.item36.respetoa=vm.items[j].respestoa;
             for(var i=0;i<100;i++){
            $scope.opcions61[i]={id:i,name:i.toString(),selec:0};
        }
         //      for(var i=0;i<100;i++){
              $scope.opcions36[0]={id:0,name:"Guanya",selec:0};
           $scope.opcions36[1]={id:1,name:"Empata",selec:0};
           $scope.opcions36[2]={id:2,name:"Perd",selec:0};
             valorspos36[0]=100;
             valorspos36[1]=66;
             valorspos36[2]=33;
      //   }
         }
         
         var a=vm.items[j].valorsCSV.split(";"); 
         var valors=vm.items[j].valoresposbiles.split(";");
         var c=[];
         var valorspos=[];
         var seleccionat=[];
         if ((vm.items[j].iditem===35)||(vm.items[j].iditem===36)){
             a=['Guanya','Empata','Perd'];
             valors=[100,66,33];
              for(var i=0;i<a.length;i++){
             c[i]={id:i,name:a[i],selec:0};
             valorspos[i]=valors[i];
             
         }
         }else{
         if ((vm.items[j].iditem===42)||(vm.items[j].iditem===43)){
             for(var i=0;i<100;i++){
             c[i]={id:i,name:i.toString(),selec:0};
             valorspos[i]=i.toString();
         }
         }else{
         for(var i=0;i<a.length;i++){
             c[i]={id:i,name:a[i],selec:0};
             valorspos[i]=valors[i];
         }}}
    //$scope.opcions[j]([{ id: 1, name: "Gairebé mai"}, { id: 2, name:"Algunes vegades"}, { id: 3, name:"Sovint"}, { id: 4, name:"Gairebé sempre"}]);
 $scope.opcions[j]=c;
 $scope.valors[j]=valorspos;
 
 
      }
      $scope.seleccionat=[];
      var d=0;
       for (var f=0;f<vm.valoracions.length;f++)
              {
                 // for (var g=0;g<vm.items.length;g++)
                      
        //        if(vm.items[g].iditem===vm.valoracions[f].item) 
         //   {
            if(vm.valoracions[f].item===44){
               // alert(vm.valoracions[f].observacions);
                $scope.observ=vm.valoracions[f].observacions;}
      //      }else{
                    if(vm.valoracions[f].item===35){
                         var i=valorspos35.indexOf(vm.valoracions[f].vporc.toString());
                        if(vm.valoracions[f].vporc===33){
                            vm.init35='Perd';
                        }
                         if(vm.valoracions[f].vporc===66){
                            vm.init35='Empata';
                        }
                         if(vm.valoracions[f].vporc===100){
                            vm.init35='Guanya';
                        }
                       
            vm.init60= vm.valoracions[f].observacions;
                       //  vm.init35=i.toString();
                      // vm.init35=$scope.opcions35[i].name;
                    }
                     if(vm.valoracions[f].item===36){
                         var i=valorspos36.indexOf(vm.valoracions[f].vporc.toString());
                        // vm.init36=i.toString();
                        
                         if(vm.valoracions[f].vporc===33){
                            vm.init36='Perd';
                        }
                         if(vm.valoracions[f].vporc===66){
                            vm.init36='Empata';
                        }
                         if(vm.valoracions[f].vporc===100){
                            vm.init36='Guanya';
                        }
                       vm.init61= vm.valoracions[f].observacions;
                    }
                     if(vm.valoracions[f].item===8){
                         var i=valorspos8.indexOf(vm.valoracions[f].vporc.toString());
                         vm.init8=$scope.opcions8[i].name;
                    }
                     if(vm.valoracions[f].item===9){
                         var i=valorspos9.indexOf(vm.valoracions[f].vporc.toString());
                         vm.init9=$scope.opcions9[i].name;
                    }
                     if(vm.valoracions[f].item===17){
                         var i=valorspos17.indexOf(vm.valoracions[f].vporc.toString());
                         vm.init17=$scope.opcions17[i].name;
                    }
                    
                     if(vm.valoracions[f].item===18){
                         var i=valorspos18.indexOf(vm.valoracions[f].vporc.toString());
                         vm.init18=$scope.opcions18[i].name;
                    }
                    
                     if(vm.valoracions[f].item===19){
                         var i=valorspos19.indexOf(vm.valoracions[f].vporc.toString());
                         vm.init19=$scope.opcions19[i].name;
                    }
                      if(vm.valoracions[f].item===20){
                         
                         var i=valorspos20.indexOf(vm.valoracions[f].vporc.toString());
                   
                       
                        vm.init20=$scope.opcions20[i].name;
                    }
                     if(vm.valoracions[f].item===21){
                         var i=valorspos21.indexOf(vm.valoracions[f].vporc.toString());
                         vm.init21=$scope.opcions21[i].name;
                    }
                     if(vm.valoracions[f].item===22){
                         var i=valorspos22.indexOf(vm.valoracions[f].vporc.toString());
                         vm.init22=$scope.opcions22[i].name;
                    }
                     if(vm.valoracions[f].item===23){
                         var i=valorspos23.indexOf(vm.valoracions[f].vporc.toString());
                         vm.init23=$scope.opcions23[i].name;
                    }
                     if(vm.valoracions[f].item===24){
                         var i=valorspos24.indexOf(vm.valoracions[f].vporc.toString());
                         vm.init24=$scope.opcions24[i].name;
                    }
                     if(vm.valoracions[f].item===25){
                         var i=valorspos25.indexOf(vm.valoracions[f].vporc.toString());
                         vm.init25=$scope.opcions25[i].name;
                    }
                    
                     if(vm.valoracions[f].item===26){
                         var i=valorspos26.indexOf(vm.valoracions[f].vporc.toString());
                         vm.init26=$scope.opcions26[i].name;
                    }
                     if(vm.valoracions[f].item===27){
                         var i=valorspos27.indexOf(vm.valoracions[f].vporc.toString());
                         vm.init27=$scope.opcions27[i].name;
                    }
                     if(vm.valoracions[f].item===28){
                         var i=valorspos28.indexOf(vm.valoracions[f].vporc.toString());
                         vm.init28=$scope.opcions28[i].name;
                    }
                     if(vm.valoracions[f].item===29){
                         var i=valorspos29.indexOf(vm.valoracions[f].vporc.toString());
                         vm.init29=$scope.opcions29[i].name;
                    }
                     if(vm.valoracions[f].item===30){
                         var i=valorspos30.indexOf(vm.valoracions[f].vporc.toString());
                         vm.init30=$scope.opcions30[i].name;
                    }
                     if(vm.valoracions[f].item===31){
                         var i=valorspos31.indexOf(vm.valoracions[f].vporc.toString());
                         vm.init31=$scope.opcions31[i].name;
                    }
                      if(vm.valoracions[f].item===37){
                         var i=valorspos37.indexOf(vm.valoracions[f].vporc.toString());
                       
                         vm.init37=$scope.opcions37[i].name;
                    }
                      if(vm.valoracions[f].item===38){
                         var i=valorspos38.indexOf(vm.valoracions[f].vporc.toString());
                         vm.init38=$scope.opcions38[i].name;
                    }
                      if(vm.valoracions[f].item===39){
                         var i=valorspos39.indexOf(vm.valoracions[f].vporc.toString());
                         vm.init39=$scope.opcions39[i].name;
                    }
                      if(vm.valoracions[f].item===40){
                         var i=valorspos40.indexOf(vm.valoracions[f].vporc.toString());
                         
                         vm.init40=$scope.opcions40[i].name;
                    }
                    if(vm.valoracions[f].item===41){
                         var i=valorspos41.indexOf(vm.valoracions[f].vporc.toString());
                         vm.init41=$scope.opcions41[i].name;
                    }
                    if(vm.valoracions[f].item===42){
                         var i=valorspos42.indexOf(vm.valoracions[f].vporc.toString());
                         vm.init42=$scope.opcions42[i].name;
                    }
                    if(vm.valoracions[f].item===43){
                         var i=valorspos43.indexOf(vm.valoracions[f].vporc.toString());
                         vm.init43=$scope.opcions43[i].name;
                    }
                      //  { //alert(g.toString()+"- idtiem:"+vm.valoracions[f].item+"-vproc:"+vm.valoracions[f].vporc.toString());
                        //      $scope.seleccionat[g]=$scope.valors[g].indexOf(vm.valoracions[f].vporc.toString());
                        //      d=$scope.opcions[g][$scope.seleccionat[g]].name;
                              //  alert($scope.valors[g][$scope.seleccionat[g]]+"cporc:"+vm.valoracions[f].vporc+"-index:"+ $scope.seleccionat[g]+$scope.opcions[g][$scope.seleccionat[g]].name+"selec:·"+$scope.opcions[g][$scope.seleccionat[g]].selec);
                               //$scope.seleccionat[g]=d;  
                     //          $scope.opcions[g][$scope.seleccionat[g]].selec=1;
                     //    }
                  
              }
       
     var  local=0;
   var visitant=1;
  
   
   
   if (resultat[0].idEquipo===resultat[0].idLocal){
       local=0;
       visitant=1;
   } else{
       local=1;
       visitant=0;
   }
    
    vm.campionat=resultat[local].campionat;
    vm.data=resultat[local].fecha.replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1');;
    vm.elocal=resultat[local].elocal;
    vm.evisitant=resultat[local].evisitant;  
    vm.cc=resultat[local].cc;
    vm.ppl=resultat[local].pp;
    vm.ppv=resultat[visitant].pp;
     vm.ptgl=resultat[local].ptg;
    vm.ptgv=resultat[visitant].ptg;
     vm.pttl=resultat[local].ptt;
    vm.pttv=resultat[visitant].ptt;
     vm.ptel=resultat[local].pte;
    vm.ptev=resultat[visitant].pte;
      vm.pdtl=resultat[local].pdt;
    vm.pdtv=resultat[visitant].pdt;
      vm.pdpl=resultat[local].pdp;
    vm.pdpv=resultat[visitant].pdp;
     vm.marcadorl=resultat[local].marcador;
    vm.marcadorv=resultat[visitant].marcador;
    vm.pvpl=resultat[local].verdplay;
    vm.pvpv=resultat[visitant].verdplay;
     vm.tvl=resultat[local].tv;
    vm.tvv=resultat[visitant].tv;
    vm.pl=resultat[local].puntsLocal;
     vm.pv=resultat[visitant].puntsVisitant;
    if (resultat[local].suspendido){
        vm.suspes="PARTIT SUSPES!!";
    }else{
         vm.suspes=resultat[local].totpunts+ " - "+resultat[visitant].totpunts;
    }
  
  if (perfil==='1') $scope.perfilnom="Tutor/a de grada";
  if (perfil==='2') $scope.perfilnom="Públic";
  if (perfil==='4') $scope.perfilnom="Tècnic/a";
  if (perfil==='5') $scope.perfilnom="Tutor/a de joc";

  if (equip===resultat[0].idLocal.toString()){
      $scope.equipnom=vm.elocal;
  }else{
      $scope.equipnom=vm.evisitant;
  }
 vm.click=function (){
     var r=true;
        if(parseInt($scope.select60)+parseInt($scope.select61)!==16){
            r=confirm("La suma del marcador es diferent de 16");
           
       
        
     }
       if ( $scope.select41.length>2 || $scope.select41.length===0 || $scope.select37.length>2 || $scope.select37.length===0){
             alert('Si us plau, indica si els equips han rebut Targetes Verdes');
             r=false;
         };
               if(r===true)
        {
  if (vm.valoracions.length>7){
    for(var j=0;j<vm.valoracions.length;j++)
     {    
         var v={idvaloracion:0,idpartido:0,iditemvalorado:0,vporc:0,vmaximo:0,vvalor:0.00,idperfil:0,idusuario:0,idequipo:0,observaciones:'',idconsell:2};
    
         if (vm.valoracions[j].idvaloracion !== null){
             v.idvaloracion=vm.valoracions[j].idvaloracion;
            
             v.idpartido=vm.valoracions[j].idPartido;
             v.iditemvalorado=vm.valoracions[j].item;
             v.idperfil=5;
             v.idusuario=0;
             v.idequipo=vm.valoracions[j].idequipo;
             v.idconsell=2;
             
   //          if(vm.valoracions[j].item===35){
     //            v.vporc=parseInt($scope.select35);
       //          v.vmaximo=0;
        //         v.vvalor=0.00;
         //        v.observaciones=$scope.select60.toString();
     //        }
       //      if(vm.valoracions[j].item===36){
         //        v.vporc=parseInt($scope.select36);
        //         v.vmaximo=0;
          //       v.vvalor=0.00;
       //          v.observaciones=$scope.select61.toString();
         //    }
              if(vm.valoracions[j].item===35){
                 // var i=valorscsv35.indexOf($scope.select8);
                     //$scope.opcions8[i].id  
                  if(parseInt($scope.select60)>parseInt($scope.select61)) v.vporc=100;
                  if(parseInt($scope.select60)<parseInt($scope.select61)) v.vporc=35;
                   if(parseInt($scope.select60)===parseInt($scope.select61)) v.vporc=65;
                   
                 v.vmaximo=500;
                 v.vvalor= v.vporc*5;
                 v.observaciones=$scope.select60.toString();
             }
              if(vm.valoracions[j].item===36){
                 // var i=valorscsv9.indexOf($scope.select9);
                     //$scope.opcions8[i].id  
                  if(parseInt($scope.select61)>parseInt($scope.select60)) v.vporc=100;
                  if(parseInt($scope.select61)<parseInt($scope.select60)) v.vporc=35;
                   if(parseInt($scope.select61)===parseInt($scope.select60)) v.vporc=65;
                   
                 v.vmaximo=500;
                 v.vvalor= v.vporc*5;
                 v.observaciones=$scope.select61.toString();
                
             }
            
         
          if(vm.valoracions[j].item===17){
                  var i=valorscsv17.indexOf($scope.select17);
                     v.vporc=(i+1)*25; 
                     v.vvalor=(i+1)*12.50;
                     v.observaciones="";
                     v.vmaximo=50;
                    
                    }
           if(vm.valoracions[j].item===20){
                  var i=valorscsv20.indexOf($scope.select20);
                 
                     v.vporc=(i+1)*25; 
                     v.vvalor=(i+1)*12.50;
                     v.observaciones="";
                     v.vmaximo=50;
                 
                    }
                    
             if(vm.valoracions[j].item===18){
                  var i=valorscsv18.indexOf($scope.select18);
                     v.vporc=(i+1)*25; 
                     v.vvalor=(i+1)*12.50;
                     v.observaciones="";
                     v.vmaximo=50;
              
                    }
           if(vm.valoracions[j].item===21){
                  var i=valorscsv21.indexOf($scope.select21);
                     v.vporc=(i+1)*25; 
                     v.vvalor=(i+1)*12.50;
                     v.observaciones="";
                     v.vmaximo=50;
                
                    }
                    
           if(vm.valoracions[j].item===19){
                  var i=valorscsv19.indexOf($scope.select19);
                     v.vporc=(i+1)*25; 
                     v.vvalor=(i+1)*12.50;
                     v.observaciones="";
                     v.vmaximo=50;
                 
                    }
           if(vm.valoracions[j].item===22){
                  var i=valorscsv22.indexOf($scope.select22);
                     v.vporc=(i+1)*25; 
                     v.vvalor=(i+1)*12.50;
                     v.observaciones="";
                     v.vmaximo=50;
                   
                    }
                
               if(vm.valoracions[j].item===23){
                  var i=valorscsv23.indexOf($scope.select23);
                     v.vporc=(i+1)*25; 
                     v.vvalor=(i+1)*12.50;
                     v.observaciones="";
                     v.vmaximo=50;
                   
                    }
           if(vm.valoracions[j].item===26){
                  var i=valorscsv26.indexOf($scope.select26);
                     v.vporc=(i+1)*25; 
                     v.vvalor=(i+1)*12.50;
                     v.observaciones="";
                     v.vmaximo=50;
                    
                    }
                    
                     if(vm.valoracions[j].item===24){
                  var i=valorscsv24.indexOf($scope.select24);
                     v.vporc=(i+1)*25; 
                     v.vvalor=(i+1)*12.5;
                     v.observaciones="";
                     v.vmaximo=50;
                   
                    }
           if(vm.valoracions[j].item===27){
                  var i=valorscsv27.indexOf($scope.select27);
                     v.vporc=(i+1)*25; 
                     v.vvalor=(i+1)*12.5;
                     v.observaciones="";
                     v.vmaximo=50;
                    
                    }
               if(vm.valoracions[j].item===25){
                  var i=valorscsv25.indexOf($scope.select25);
                     v.vporc=(i+1)*25; 
                     v.vvalor=(i+1)*12.50;
                     v.observaciones="";
                     v.vmaximo=50;
                   
                    }
           if(vm.valoracions[j].item===28){
                  var i=valorscsv28.indexOf($scope.select28);
                     v.vporc=(i+1)*25; 
                     v.vvalor=(i+1)*12.50;
                     v.observaciones="";
                     v.vmaximo=50;
                 
                    }  
                    
               if(vm.valoracions[j].item===29){
                  var i=valorscsv29.indexOf($scope.select29);
                     v.vporc=i*100; 
                     v.vvalor=i*25;
                     v.observaciones="";
                     v.vmaximo=25;
                  
                    }
           if(vm.valoracions[j].item===38){
                  var i=valorscsv38.indexOf($scope.select38);
                     v.vporc=i*100; 
                     v.vvalor=i*25;
                     v.observaciones="";
                     v.vmaximo=25;
                   
                    }  
                    
               if(vm.valoracions[j].item===30){
                  var i=valorscsv30.indexOf($scope.select30);
                     v.vporc=i*100; 
                     v.vvalor=i*25;
                     v.observaciones="";
                     v.vmaximo=25;
                  
                    }
           if(vm.valoracions[j].item===39){
                  var i=valorscsv39.indexOf($scope.select39);
                     v.vporc=i*100; 
                     v.vvalor=i*25;
                     v.observaciones="";
                     v.vmaximo=25;
                  
                    }  
                    
            if(vm.valoracions[j].item===31){
             
                  var i=valorscsv31.indexOf($scope.select31);
                     v.vporc=i*100; 
                     v.vvalor=i*25;
                     v.observaciones="";
                     v.vmaximo=25;
                    
                    }
           if(vm.valoracions[j].item===40){
                  var i=valorscsv40.indexOf($scope.select40);
                     v.vporc=i*100; 
                     v.vvalor=i*25;
                     v.observaciones="";
                     v.vmaximo=25;
                   
                    }
                    
             if(vm.valoracions[j].item===37){
               
                  var i=valorscsv37.indexOf($scope.select37);
                     v.vporc=i*100; 
                     v.vvalor=i*100;
                     v.observaciones="";
                     v.vmaximo=100;
                   
                    }
           if(vm.valoracions[j].item===41){
                  var i=valorscsv41.indexOf($scope.select41);
                     v.vporc=i*100; 
                     v.vvalor=i*100;
                     v.observaciones="";
                     v.vmaximo=100;
                    
                    }          
             if(vm.valoracions[j].item===42){
                 v.vporc=parseInt($scope.select42);
                 v.vmaximo=0;
                 v.vvalor=0.00;
                 v.observaciones="";
             }
             if(vm.valoracions[j].item===43){
                 v.vporc=parseInt($scope.select43);
                 v.vmaximo=0;
                 v.vvalor=0.00;
                 v.observaciones="";
             }   
             
              if(vm.valoracions[j].item===44){
                 v.vporc=0;
                 v.vmaximo=0;
                 v.vvalor=0.00;
                 v.observaciones=$scope.observ;
                 //alert($scope.observ);
             }   
           var  fecha=new Date();
        //   v.fecha_hora=fecha.getFullYear()+"-"+(fecha.getMonth() + 1) + "-" + fecha.getDate() + " " + fecha.getHours() + ":" + fecha.getMinutes() + ":" + fecha.getSeconds();
        
             valoracions.update({idpartit: v.idpartido,idequip:v.idequipo,idperfil:v.idperfil}, v);
            } 
              }
              //alert("voy");
          var url = $state.href("home.resultats",{idPartit: v.idpartido});
           window.open(url,'_self');
          }
          else{
              for(var j=0;j<vm.items.length;j++)
     {    
            var v={idpartido:0,iditemvalorado:0,vporc:0,vmaximo:0,vvalor:0.00,idperfil:0,idusuario:0,idequipo:0,observaciones:'',idconsell:2};
             
             v.idpartido=resultat[local].idPartido;
             v.iditemvalorado=vm.items[j].iditem;
             v.idperfil=5;
             v.idusuario=0;
             //alert(vm.items[j].iditem);
             v.idconsell=2;
             
             if(vm.items[j].iditem===35){
                 v.vporc=parseInt($scope.select35);
                 v.vmaximo=0;
                 v.vvalor=0.00;
                 v.observaciones="";
                 v.idequipo=resultat[local].idLocal;
                  if(parseInt($scope.select60)>parseInt($scope.select61)) v.vporc=100;
                  if(parseInt($scope.select60)<parseInt($scope.select61)) v.vporc=35;
                   if(parseInt($scope.select60)===parseInt($scope.select61)) v.vporc=65;
                   
                 v.vmaximo=500;
                 v.vvalor= v.vporc*5;
                 v.observaciones=$scope.select60.toString();
             }
             if(vm.items[j].iditem===36){
                 v.vporc=parseInt($scope.select36);
                 v.vmaximo=0;
                 v.vvalor=0.00;
                 v.observaciones="";
                  v.idequipo=resultat[local].idVisitant;
                   if(parseInt($scope.select61)>parseInt($scope.select60)) v.vporc=100;
                  if(parseInt($scope.select61)<parseInt($scope.select60)) v.vporc=35;
                   if(parseInt($scope.select61)===parseInt($scope.select60)) v.vporc=65;
                   
                 v.vmaximo=500;
                 v.vvalor= v.vporc*5;
                 v.observaciones=$scope.select61.toString();
             }
              if(vm.items[j].iditem===8){
                  var i=valorscsv8.indexOf($scope.select8);
                     //$scope.opcions8[i].id  
                  if(parseInt($scope.select35)>parseInt($scope.select36)) v.vporc=100;
                  if(parseInt($scope.select35)<parseInt($scope.select36)) v.vporc=35;
                   if(parseInt($scope.select35)===parseInt($scope.select36)) v.vporc=65;
                   
                 v.vmaximo=500;
                 v.vvalor=v.vporc*5;
                 v.observaciones="";
                  v.idequipo=resultat[local].idLocal;
             }
              if(vm.items[j].iditem===9){
                  var i=valorscsv9.indexOf($scope.select9);
                     //$scope.opcions8[i].id  
                  if(parseInt($scope.select36)>parseInt($scope.select35)) v.vporc=100;
                  if(parseInt($scope.select36)<parseInt($scope.select35)) v.vporc=35;
                   if(parseInt($scope.select36)===parseInt($scope.select35)) v.vporc=65;
                   
                 v.vmaximo=500;
                 v.vvalor=v.vporc*5;
                 v.observaciones="";
                  v.idequipo=resultat[local].idVisitant;
             }
            
         
          if(vm.items[j].iditem===17){
                  var i=valorscsv17.indexOf($scope.select17);
                     v.vporc=(i+1)*25; 
                     v.vvalor=(i+1)*12.5;
                     v.observaciones="";
                     v.vmaximo=50;
                      v.idequipo=resultat[local].idLocal;
                    
                    }
           if(vm.items[j].iditem===20){
                  var i=valorscsv20.indexOf($scope.select20);
                 
                     v.vporc=(i+1)*25; 
                     v.vvalor=(i+1)*12.50;
                     v.observaciones="";
                     v.vmaximo=50;
                      v.idequipo=resultat[local].idVisitant;
                 
                    }
                    
             if(vm.items[j].iditem===18){
                  var i=valorscsv18.indexOf($scope.select18);
                     v.vporc=(i+1)*25; 
                     v.vvalor=(i+1)*12.50;
                     v.observaciones="";
                     v.vmaximo=50;
                      v.idequipo=resultat[local].idLocal;
              
                    }
           if(vm.items[j].iditem===21){
                  var i=valorscsv21.indexOf($scope.select21);
                     v.vporc=(i+1)*25; 
                     v.vvalor=(i+1)*12.50;
                     v.observaciones="";
                     v.vmaximo=50;
                 v.idequipo=resultat[local].idVisitant;
                    }
                    
           if(vm.items[j].iditem===19){
                  var i=valorscsv19.indexOf($scope.select19);
                     v.vporc=(i+1)*25; 
                     v.vvalor=(i+1)*12.50;
                     v.observaciones="";
                     v.vmaximo=50;
                     v.idequipo=resultat[local].idLocal;
                    }
           if(vm.items[j].iditem===22){
                  var i=valorscsv22.indexOf($scope.select22);
                     v.vporc=(i+1)*25; 
                     v.vvalor=(i+1)*12.50;
                     v.observaciones="";
                     v.vmaximo=50;
                    v.idequipo=resultat[local].idVisitant;
                    }
                
               if(vm.items[j].iditem===23){
                  var i=valorscsv23.indexOf($scope.select23);
                     v.vporc=(i+1)*25; 
                     v.vvalor=(i+1)*12.50;
                     v.observaciones="";
                     v.vmaximo=50;
                    v.idequipo=resultat[local].idLocal;
                    }
           if(vm.items[j].iditem===26){
                  var i=valorscsv26.indexOf($scope.select26);
                     v.vporc=(i+1)*25; 
                     v.vvalor=(i+1)*12.50;
                     v.observaciones="";
                     v.vmaximo=50;
                     v.idequipo=resultat[local].idVisitant;
                    }
                    
                     if(vm.items[j].iditem===24){
                  var i=valorscsv24.indexOf($scope.select24);
                     v.vporc=(i+1)*25; 
                     v.vvalor=(i+1)*12.50;
                     v.observaciones="";
                     v.vmaximo=50;
                    v.idequipo=resultat[local].idLocal;
                    }
           if(vm.items[j].iditem===27){
                  var i=valorscsv27.indexOf($scope.select27);
                     v.vporc=(i+1)*25; 
                     v.vvalor=(i+1)*12.50;
                     v.observaciones="";
                     v.vmaximo=50;
                     v.idequipo=resultat[local].idVisitant;
                    }
               if(vm.items[j].iditem===25){
                  var i=valorscsv25.indexOf($scope.select25);
                     v.vporc=(i+1)*25; 
                     v.vvalor=(i+1)*12.50;
                     v.observaciones="";
                     v.vmaximo=50;
                    v.idequipo=resultat[local].idLocal;
                    }
           if(vm.items[j].iditem===28){
                  var i=valorscsv28.indexOf($scope.select28);
                     v.vporc=(i+1)*25; 
                     v.vvalor=(i+1)*12.50;
                     v.observaciones="";
                     v.vmaximo=50;
                  v.idequipo=resultat[local].idVisitant;
                    }  
                    
               if(vm.items[j].iditem===29){
                  var i=valorscsv29.indexOf($scope.select29);
                 
                     v.vporc=i*100; 
                     v.vvalor=i*25;
                     v.observaciones="";
                     v.vmaximo=25;
                   v.idequipo=resultat[local].idLocal;
                    }
           if(vm.items[j].iditem===38){
                  var i=valorscsv38.indexOf($scope.select38);
                     v.vporc=i*100; 
                     v.vvalor=i*25;
                     v.observaciones="";
                     v.vmaximo=25;
                    v.idequipo=resultat[local].idVisitant;
                    }  
                    
               if(vm.items[j].iditem===30){
                  var i=valorscsv30.indexOf($scope.select30);
                     v.vporc=i*100; 
                     v.vvalor=i*25;
                     v.observaciones="";
                     v.vmaximo=25;
                   v.idequipo=resultat[local].idLocal;
                    }
           if(vm.items[j].iditem===39){
                  var i=valorscsv39.indexOf($scope.select39);
                     v.vporc=i*100; 
                     v.vvalor=i*25;
                     v.observaciones="";
                     v.vmaximo=25;
                   v.idequipo=resultat[local].idVisitant;
                    }  
                    
            if(vm.items[j].iditem===31){
             
                  var i=valorscsv31.indexOf($scope.select31);
                     v.vporc=i*100; 
                     v.vvalor=i*25;
                     v.observaciones="";
                     v.vmaximo=25;
                     v.idequipo=resultat[local].idLocal;
                    }
           if(vm.items[j].iditem===40){
                  var i=valorscsv40.indexOf($scope.select40);
                     v.vporc=i*100; 
                     v.vvalor=i*25;
                     v.observaciones="";
                     v.vmaximo=25;
                    v.idequipo=resultat[local].idVisitant;
                    }
                    
             if(vm.items[j].iditem===37){
               
                  var i=valorscsv37.indexOf($scope.select37);
                     v.vporc=i*100; 
                     v.vvalor=i*100;
                     v.observaciones="";
                     v.vmaximo=100;
                    v.idequipo=resultat[local].idLocal;
                    }
           if(vm.items[j].iditem===41){
                  var i=valorscsv41.indexOf($scope.select41);
                     v.vporc=i*100; 
                     v.vvalor=i*100;
                     v.observaciones="";
                     v.vmaximo=100;
                     v.idequipo=resultat[local].idVisitant;
                    }          
             if(vm.items[j].iditem===42){
                 v.vporc=parseInt($scope.select42);
                 v.vmaximo=0;
                 v.vvalor=0.00;
                 v.observaciones="";
                  v.idequipo=resultat[local].idVisitant;
             }
             if(vm.items[j].iditem===43){
                 v.vporc=parseInt($scope.select43);
                 v.vmaximo=0;
                 v.vvalor=0.00;
                 v.observaciones="";
                  v.idequipo=resultat[local].idLocal;
             }   
             
              if(vm.items[j].iditem===44){
                 
                 v.vporc=0;
                 v.vmaximo=0;
                 v.vvalor=0.00;
                 v.observaciones=$scope.observ;
                  v.idequipo=resultat[local].idLocal;
             }   
           var  fecha=new Date();
        //   v.fecha_hora=fecha.getFullYear()+"-"+(fecha.getMonth() + 1) + "-" + fecha.getDate() + " " + fecha.getHours() + ":" + fecha.getMinutes() + ":" + fecha.getSeconds();
        
             valoracions.insert({idpartit: v.idpartido,idequip:v.idequipo,idperfil:v.idperfil}, v);
             
              } 
              
          var url = $state.href("home.resultats",{idPartit: v.idpartido});
           window.open(url,'_self');
          }
          
          
          
          
  }
 };  
 }}());